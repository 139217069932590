routeConfig.$inject = ["$locationProvider", "$stateProvider", "$urlRouterProvider", "$urlMatcherFactoryProvider"];

export function routeConfig($locationProvider, $stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) {
    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix("");
    $urlMatcherFactoryProvider.caseInsensitive(true);
    $urlMatcherFactoryProvider.strictMode(false);

    $stateProvider
        .state("main", {
            url: "",
            resolve: {
                accessToken: [
                    "$q",
                    "Auth0Service",
                    ($q, Auth0Service) => {
                        const deferred = $q.defer();

                        if (Auth0Service.accessToken) deferred.resolve(Auth0Service.accessToken);
                        else if (!Auth0Service.accessToken) {
                            Auth0Service.renewTokens()
                                .then(() => {
                                    const accessToken = Auth0Service.accessToken;
                                    deferred.resolve(accessToken);
                                })
                                .catch((err) => deferred.reject(err));
                        }

                        return deferred.promise;
                    }
                ],
                profile: [
                    "accessToken",
                    "$q",
                    "SessionService",
                    (accessToken, $q, sessionService) => {
                        const deferred = $q.defer();
                        let profile = sessionService.getProfile();

                        if (Object.keys(profile).length) {
                            deferred.resolve(profile);
                            return deferred.promise;
                        } else {
                            sessionService
                                .refreshProfile()
                                .then(() => {
                                    profile = sessionService.getProfile();
                                    deferred.resolve(profile);
                                })
                                .catch((err) => deferred.reject(err));
                        }

                        return deferred.promise;
                    }
                ]
            }
        })
        .state("renewtokens", {
            abstract: true,
            parent: "main",
            resolve: {
                init: [
                    "FeatureService",
                    "SyncService",
                    "AlertsNotificationService",
                    "UserPreferencesService",
                    "BrandSettingsService",
                    "SupportedFileExtensionsService",
                    "IntercomService",
                    (
                        FeatureService,
                        SyncService,
                        AlertsNotificationService,
                        UserPreferencesService,
                        BrandSettingsService,
                        SupportedFileExtensionsService,
                        IntercomService
                    ) => {
                        SyncService.register(AlertsNotificationService.refresh, 300);
                        SupportedFileExtensionsService.load();
                        FeatureService.load();
                        BrandSettingsService.load();
                        IntercomService.boot();
                        return UserPreferencesService.load().then(() => true);
                    }
                ]
            }
        })
        .state("home", {
            component: "homeLayout",
            parent: "renewtokens"
        })
        .state("otherwise", {
            url: "/{path:.*}",
            resolve: {
                checkPath: [
                    "$injector",
                    "$location",
                    ($injector, $location) => {
                        const path = $location.path();
                        const ngxRouter = $injector.get("ngxRouter");

                        ngxRouter.navigateByUrl(path);
                    }
                ]
            },
            template: `
                <div ng-controller="SessionTimeoutCtrl"></div>
                <div ng-controller="CancelledOrgCtrl"></div>
            `
        });
}

routeRun.$inject = ["$transitions", "$state", "FeatureGuardFactory"];

export function routeRun($transitions, $state, FeatureGuardFactory) {
    var criteria = {
        to: function (state) {
            return state.redirectTo != null;
        }
    };

    $transitions.onBefore(criteria, function (transition) {
        var state = transition.to().redirectTo;
        return transition.router.stateService.target(state);
    });

    var criteria2 = {
        to: function (state) {
            return state.name != "callback";
        }
    };

    $transitions.onSuccess(criteria2, function () {
        FeatureGuardFactory.canActivate($state.current.url);
    });
}
